@import '~antd/dist/antd.less';

@font-face {
  font-family: 'Gotham Rounded Medium';
  font-style: normal;
  src: url('./gotham-rounded/GothamRoundedMedium_21022.ttf');
}

/* choose participants */
.ant-select-selector {
  min-height: 250px !important;
  border-radius: 15px !important;
  padding: 5px !important;
  align-items: start !important;
}

.ant-select-selection-placeholder {
  top: 25px !important;
  left: 15px !important;
  right: unset !important;
}

/* choose pair time */
.ant-radio-button-wrapper:first-child {
  border-radius: 35px 0px 0px 35px !important;
}
.ant-radio-button-wrapper:last-child {
  border-radius: 0px 35px 35px 0px !important;
}

/* timer */
.ant-statistic-content span {
  font-family: 'Gotham Rounded Medium';
  color: rgba(38, 50, 75, 0.85);
}

/* partial fix for padding bug */

input.ant-select-search__field {
  width: 100% !important;
}

@primary-color: #008579;@link-color: #008579;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: rgba(38, 50, 75, 0.85);@text-color-secondary : rgba(0, 0, 0, .45);@disabled-color : rgba(0, 0, 0, .25);@border-radius-base: 4px;@border-color-base: #d9d9d9;@box-shadow-base: 0 2px 8px rgba(0, 0, 0, 0.15);